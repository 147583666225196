<template lang="pug">
  div(v-if="!users")
    .card-panel
      h4.center
        i.fa.fa-spinner.fa-spin
        span &nbsp;
        span Loading Users
  div(v-if="users")
    .card-panel.center
      h4
        i.fa.fa-cog
        span &nbsp;
        span Users Settings

    .controls
      .search-bar
        input(type="text" v-model="searchQuery" placeholder="Search users by email..." class="search-input")
      .sort-dropdown
        label Affiliate User:
        select(v-model="selectedAffiliate" id="sorting" class="browser-default")
          option(value="") All
          option(v-for="email in getAffiliateUsers" :key="email" :value="email") {{ email }}
      .sort-dropdown
        label Sort by:
        select(v-model="sortKey" id="sorting" class="browser-default")
          option(value="id") id
          option(value="email") Email
          option(value="singles_only") Singles Only
          option(value="request_a_bet") Request a Bet
          option(value="commission_percentage") Commission Percentage
          option(value="single_stake_limit") Single Stake Limit
          option(value="affiliate") Affiliate User
          option(value="market_closing") Can close Markets
          option(value="kyc_enabled") KYC Enabled
          option(value="demo_mode") Demo Mode
          option(value="allow_credit_betting") Allow Credit Betting
          option(value="singles_sp_if_better") Singles SP if better
          option(value="multiples_sp_if_better") Multiples SP if better
          option(value="empty_bets") Empty Bets
          option(value="cancel_bets") Cancel Bets
          option(value="type") User Type

        button.btn(@click="toggleSortOrder") {{ sortOrder === 'asc' ? '▲' : '▼' }}

    .table-container(v-if="users")
      table.striped.m-0.centered
        thead
          tr
            th(@click="sortBy('type')") Type {{ getSortIndicator('type') }}
            th(@click="sortBy('email')") Email {{ getSortIndicator('email') }}
            th(@click="sortBy('singles_only')") Singles Only {{ getSortIndicator('singles_only') }}

            th(@click="sortBy('request_a_bet')") Request a Bet {{ getSortIndicator('request_a_bet') }}
            th(@click="sortBy('mp_loss_limit')") Morning Price Loss Limit{{ getSortIndicator('mp_loss_limit') }}
            th(@click="sortBy('commission_percentage')") Commission Percentage {{ getSortIndicator('commission_percentage') }}

            th(@click="sortBy('single_stake_limit')") Single Stake Limit {{ getSortIndicator('single_stake_limit') }}
            th(@click="sortBy('affiliate')")  Affiliate User {{ getSortIndicator('affiliate') }}
            th(@click="sortBy('market_closing')")  Can close Markets {{ getSortIndicator('market_closing') }}

            th(@click="sortBy('exclude_lotteries_pick_four')") Excluded from lotteries pick 4 bets {{ getSortIndicator('exclude_lotteries_pick_four') }}
            th(@click="sortBy('kyc_enabled')")  KYC Enabled {{ getSortIndicator('kyc_enabled') }}
            th(@click="sortBy('demo_mode')") Demo Mode {{ getSortIndicator('demo_mode') }}

            th(@click="sortBy('allow_credit_betting')") Allow Credit Betting {{ getSortIndicator('allow_credit_betting') }}
            th(@click="sortBy('singles_sp_if_better')") Singles SP if better {{ getSortIndicator('singles_sp_if_better') }}
            th(@click="sortBy('multiples_sp_if_better')") Multiples SP if better {{ getSortIndicator('multiples_sp_if_better') }}

            th(@click="sortBy('empty_bets')") Empty Bets {{ getSortIndicator('empty_bets') }}
            th(@click="sortBy('cancel_bets')") Cancel Bets {{ getSortIndicator('cancel_bets') }}
        tbody
          tr(v-for="(user, index) in sortedUsers", :key="user.id")
            td(:data-label="'Type'") {{ user.type }}
            td(:data-label="'Email'")
              a(:href="`/users/${user.id}`") {{ user.email }}
            td(:data-label="'Singles Only'")
              i.fa.fa-check-circle.green-text(v-if="user.singles_only")
              i.fa.fa-times-circle.red-text(v-else)

            td(:data-label="'Request a Bet'")
              i.fa.fa-check-circle.green-text(v-if="user.request_a_bet")
              i.fa.fa-times-circle.red-text(v-else)
            td(:data-label="'Morning Price Loss Limit'") {{ user.mp_loss_limit }}
            td(:data-label="'Commission Percentage'") {{ user.commission_percentage }}

            td(:data-label="'Single Stake Limit'") {{ user.single_stake_limit }}
            td(:data-label="'Affiliate User'")
              i.fa.fa-check-circle.green-text(v-if="user.affiliate")
              i.fa.fa-times-circle.red-text(v-else)
            td(:data-label="'Can close Markets'")
              i.fa.fa-check-circle.green-text(v-if="user.market_closing")
              i.fa.fa-times-circle.red-text(v-else)

            td(:data-label="'Excluded from lotteries pick 4 bets'")
              i.fa.fa-check-circle.green-text(v-if="user.exclude_lotteries_pick_four")
              i.fa.fa-times-circle.red-text(v-else)
            td(:data-label="'KYC Enabled'")
              i.fa.fa-check-circle.green-text(v-if="user.kyc_enabled")
              i.fa.fa-times-circle.red-text(v-else)
            td(:data-label="'Demo Mode'")
              i.fa.fa-check-circle.green-text(v-if="user.demo_mode")
              i.fa.fa-times-circle.red-text(v-else)

            td(:data-label="'Allow Credit Betting'")
              i.fa.fa-check-circle.green-text(v-if="user.allow_credit_betting")
              i.fa.fa-times-circle.red-text(v-else)
            td(:data-label="'Singles sp if better'")
              i.fa.fa-check-circle.green-text(v-if="user.singles_sp_if_better")
              i.fa.fa-times-circle.red-text(v-else)
            td(:data-label="'Multiples SP if better'")
              i.fa.fa-check-circle.green-text(v-if="user.multiples_sp_if_better")
              i.fa.fa-times-circle.red-text(v-else)

            td(:data-label="'Empty Bets'")
              i.fa.fa-check-circle.green-text(v-if="user.empty_bets")
              i.fa.fa-times-circle.red-text(v-else)
            td(:data-label="'Cancel Bets'")
              i.fa.fa-check-circle.green-text(v-if="user.cancel_bets")
              i.fa.fa-times-circle.red-text(v-else)
 </template>

<script>
import { ref, computed, onMounted } from 'vue';
import axios_client from '../../utils/axios_client';

export default {
  setup() {
    const users = ref(null);
    const searchQuery = ref('');
    const sortKey = ref('id');
    const sortOrder = ref('asc');
    const selectedAffiliate = ref("");

    const fetchUsers = async () => {
      try {
        const response = await axios_client.get(`settings.json`);

        if (response.data) {
          users.value = response.data;
        } else {
          console.error('Unexpected response structure:', response);
        }
      } catch (error) {
        console.error(
          'Error fetching users:',
          error.response ? error.response.data : error.message,
        );
      }
    };

    const getAffiliateUsers = computed(() => {
      if (!users.value) return [];
      const emails = new Set();
      users.value.forEach((user) => {
        if (user.affiliate_user_email) {
          emails.add(user.affiliate_user_email);
        }
      });
      return [...emails];
    });

    const filteredUsers = computed(() => {
      if (!users.value) return [];
      return users.value.filter(user => {
        const matchesSearch = user.email
          .toLowerCase()
          .includes(searchQuery.value.toLowerCase());
        const matchesAffiliate =
          !selectedAffiliate.value || user.affiliate_user_email === selectedAffiliate.value;
        return matchesSearch && matchesAffiliate;
      }
      );
    });

    const sortedUsers = computed(() => {
    return [...filteredUsers.value].sort((a, b) => {
      let aValue = a[sortKey.value];
      let bValue = b[sortKey.value];

      if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (sortOrder.value === 'asc') {
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      } else {
        return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
      }
    });
  });


    const sortBy = key => {
      if (sortKey.value === key) {
        sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
      } else {
        sortKey.value = key;
        sortOrder.value = 'asc';
      }
    };

    const toggleSortOrder = () => {
      sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
    };

    const getSortIndicator = key => {
      if (sortKey.value === key) {
        return sortOrder.value === 'asc' ? '▲' : '▼';
      }
      return '';
    };

    onMounted(() => {
      fetchUsers();
    });

    return {
      users,
      searchQuery,
      filteredUsers,
      sortKey,
      sortOrder,
      sortedUsers,
      toggleSortOrder,
      sortBy,
      getSortIndicator,
      getAffiliateUsers,
      selectedAffiliate,
    };
  },
};
</script>