import Chart from 'chart.js/auto';


var off_time_analysis_graph = document.getElementById('off_time_analysis_graph');
if (off_time_analysis_graph) {
  new Chart(off_time_analysis_graph, {
    type: 'line',
    data: {
      labels: window.labels,
      datasets: [{
        label: 'Off Time Delays Seconds',
        data: window.data,
        borderColor: [
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1
      }]
    },
    options: {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    }
  });
}

var fsbBetChartEl = document.getElementById('fsbBetChart')

const fsbBetsCountTotalChartData = window.fsbBetsCountTotalChartData;

if (fsbBetChartEl && fsbBetsCountTotalChartData) {
  const context = fsbBetChartEl.getContext('2d');
  var data = {
    labels: fsbBetsCountTotalChartData.map((item) => item[0]),
    datasets: [{
      label: 'Total FsbBet Bet Count per Date',
      data: fsbBetsCountTotalChartData.map((item) => item[1]),
      borderColor: 'rgba(75, 192, 192, 1)',
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      fill: false
    }]
  };

  new Chart(context, {
    type: 'line',
    data: data,
  });
}

// Bets Grouped By Day Chart
const betsChartEl = document.getElementById('betsGroupedByDay');
const betsGroupedByDayData = window.betsGroupedByDayChartData;
if (betsChartEl && betsGroupedByDayData) {
  const context = betsChartEl.getContext('2d');
  var betData = {
    labels: betsGroupedByDayData.map((item) => item[0]),
    datasets: [{
      label: 'Bets per Day',
      data: betsGroupedByDayData.map((item) => item[1]),
      borderColor: 'rgba(75, 192, 192, 1)',
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      fill: false
    }]
  };

  new Chart(context, {
    type: 'line',
    data: betData,
  });
}

// Total Stakes Grouped By Day Chart
const stakesChartEl = document.getElementById('totalStakesGroupedByDay');
const stakesGroupedByDayData = window.betsGroupedByDayTotalStakesChartData;
if (stakesChartEl && stakesGroupedByDayData) {
  const context = stakesChartEl.getContext('2d');
  var stakesData = {
    labels: stakesGroupedByDayData.map((item) => item[0]),
    datasets: [{
      label: 'Total Stakes per Day',
      data: stakesGroupedByDayData.map((item) => item[1]),
      borderColor: 'rgba(153, 102, 255, 1)',
      backgroundColor: 'rgba(153, 102, 255, 0.2)',
      fill: false
    }]
  };

  new Chart(context, {
    type: 'line',
    data: stakesData,
  });
}

// Settled Returns Grouped By Day Chart
const returnsChartEl = document.getElementById('settledReturnsGroupedByDay');
const returnsGroupedByDayData = window.betsGroupedByDaySettledReturnsChartData;
if (returnsChartEl && returnsGroupedByDayData) {
  const context = returnsChartEl.getContext('2d');
  var returnsData = {
    labels: returnsGroupedByDayData.map((item) => item[0]),
    datasets: [{
      label: 'Settled Returns per Day',
      data: returnsGroupedByDayData.map((item) => item[1]),
      borderColor: 'rgba(255, 159, 64, 1)',
      backgroundColor: 'rgba(255, 159, 64, 0.2)',
      fill: false
    }]
  };

  new Chart(context, {
    type: 'line',
    data: returnsData,
  });
}

